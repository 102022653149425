<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Suppose you have an unknown volatile organic compound in the liquid phase. At
        <number-value :value="T1" unit="^\circ\text{C,}" />
        the vapor pressure of the liquid is
        <number-value :value="P1" unit="\text{kPa,}" />
        while at
        <number-value :value="T2" unit="^\circ\text{C,}" />
        its vapor pressure is
        <number-value :value="P2" unit="\text{kPa.}" />
        Using this information, estimate the enthalpy of vaporization
        <stemble-latex content="$\Delta\text{H}_\text{vap},$" />
        for the unknown liquid in kJ/mol.
      </p>

      <calculation-input
        v-model="inputs.Hvap"
        prepend-text="$\Delta\text{H}_\text{vap}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question400',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        Hvap: null,
      },
    };
  },
  computed: {
    T1() {
      return this.taskState.getValueBySymbol('T1').content;
    },
    T2() {
      return this.taskState.getValueBySymbol('T2').content;
    },
    P1() {
      return this.taskState.getValueBySymbol('P1').content;
    },
    P2() {
      return this.taskState.getValueBySymbol('P2').content;
    },
  },
};
</script>
